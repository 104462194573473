/* global LANG:readonly */

const mesi = [
	LANG.cal_january,
	LANG.cal_february,
	LANG.cal_march,
	LANG.cal_april,
	LANG.cal_mayl,
	LANG.cal_june,
	LANG.cal_july,
	LANG.cal_august,
	LANG.cal_september,
	LANG.cal_october,
	LANG.cal_november,
	LANG.cal_december,
];
const mesiShort = [
	LANG.cal_jan,
	LANG.cal_feb,
	LANG.cal_mar,
	LANG.cal_apr,
	LANG.cal_may,
	LANG.cal_jun,
	LANG.cal_jul,
	LANG.cal_aug,
	LANG.cal_sep,
	LANG.cal_oct,
	LANG.cal_nov,
	LANG.cal_dec,
];
const giorni = [
	LANG.cal_sun,
	LANG.cal_mon,
	LANG.cal_tue,
	LANG.cal_wed,
	LANG.cal_thu,
	LANG.cal_fri,
	LANG.cal_sat,
];
const giorniIniziali = [
	LANG.cal_su,
	LANG.cal_mo,
	LANG.cal_tu,
	LANG.cal_we,
	LANG.cal_th,
	LANG.cal_fr,
	LANG.cal_sa,
];

const datePickerInit = () => {
	const dataEl = document.querySelectorAll('.datepicker');
	M.Datepicker.init(dataEl, {
		showClearBtn: true,
		minDate: new Date(),
		firstDay: 1,
		format: 'dd/mm/yyyy',
		i18n: {
			cancel: LANG.cancel,
			clear: LANG.clear_data,
			monthsShort: mesiShort,
			months: mesi,
			weekdaysShort: giorni,
			weekdaysAbbrev: giorniIniziali,
		},
	});
};

const onClickHandlerDatePicker = (element) => {
	if (!element) {
		return;
	}

	M.Datepicker.init(element, {
		showClearBtn: true,
		minDate: new Date(),
		firstDay: 1,
		format: 'dd/mm/yyyy',
		i18n: {
			cancel: LANG.cancel,
			clear: LANG.clear_data,
			monthsShort: mesiShort,
			months: mesi,
			weekdaysShort: giorni,
			weekdaysAbbrev: giorniIniziali,
		},
	});
};

const onClickHandlerHourPicker = (element) => {
	if (!element) {
		return;
	}
	M.Timepicker.init(element, {
		showClearBtn: true,
		twelveHour: false,
		defaultTime: 'now',
		i18n: {
			cancel: LANG.cancel,
			clear: LANG.clear_hour,
			done: LANG.done,
		},
	});
};

export { datePickerInit, onClickHandlerDatePicker, onClickHandlerHourPicker };
