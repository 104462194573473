const sendXhrRequest = (endpoint,dataObj,method) => new Promise((resolve, reject) => {
	const request = new XMLHttpRequest();
	request.onload = () => {
		if (request.status >= 200 && request.status < 300) {
			resolve(request.response);
		} else {
			reject(new Error(request.response));
		}
	};
	request.onerror = () => {
		reject(new Error(request.response));
	};
	request.open(method, endpoint); 
  	request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
	request.send(dataObj);
});

export default sendXhrRequest;
