/**
 * Funzione utilizzata per salvare l'attuale ordinamento di CTA su session storage.
 *
 * @param string keyName   nome della chiave.
 * @param string keyValue  contiene il valore da memorizzare.
 */
const storeSortKey = (keyName, keyValue) => {
	sessionStorage.setItem(keyName, keyValue);
};

/**
 * Funzione utilizzata per ripristinare un eventuale ordinamento di CTA.
 *
 * @param string keyName   nome della chiave.
 */
const restoreAnySort = (keyName) => {
	if (sessionStorage.getItem(keyName)) {
		const data = sessionStorage.getItem(keyName).split('|');
		document.getElementById(data[0]).click();
		if (data[1] === 'descending') {
			document.getElementById(data[0]).click();
		}
	}
};

/**
 * Funzione utilizzata per istanziare i vari Listener di ordinamento sulla tabella.
 */
const sortTableInit = () => {
	const tableIndexKey = document.querySelectorAll('.sortable-th');
	if (tableIndexKey.length > 0) {
		restoreAnySort('topic-sort');
		tableIndexKey.forEach((th) => {
			th.addEventListener('click', () => storeSortKey('topic-sort', `${th.id}|${th.ariaSort}`));
		});
	}
};

export default sortTableInit;
