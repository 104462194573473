import sendXhrRequest from './request';
import Handlebars from './handlebars';

let formUpdate = [];
let ctaToRemove = [];
const initTopic = () => {
	let autosuggestionCta = document.querySelectorAll('.autocomplete_cta');
	if ( autosuggestionCta ) {
		for (let i = autosuggestionCta.length - 1; i >= 0; i--) {
			autosuggestionCta[i].addEventListener('keyup', autocompleteCta);
		}
	}
    const saveBtn = document.querySelector('.save-cta-association');
    if ( saveBtn ) {
    	saveBtn.addEventListener('click',saveAll);
    }
    
    const ctaWrapper = document.querySelectorAll('.cta_list');
	for (let i = ctaWrapper.length - 1; i >= 0; i--) {
		calcolaPeso(ctaWrapper[i]);
	}    
	
    addEventListenerToRemoveCtaBtn();
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips, []);
    const modals = document.querySelectorAll('.modal');
    M.Modal.init(modals, {});
}

const autocompleteCta = (event) => {
	const query = event.target.value;
	const {
        div_class: divClass,
        id_topic: idTopic,
        id_subtopic: idSubTopic,
        type,
        topicName,
		cta_type
    } = event.target.dataset;
	if(query != '' && query.length > 2) {
		const endpoint = window.location.origin + '/cta/autosuggestion/';
		const data = 'search='+query+'&divClass='+divClass+'&id_topic='+idTopic+'&id_subtopic='+idSubTopic+'&type='+type+'&cta_type='+cta_type;
		sendXhrRequest(endpoint,data,'POST').then((response) => successRequestAutocomplete(response, topicName)).catch(showErrorMessage);
	}
}

const successRequestAutocomplete = (response, topicName) => {
	response = JSON.parse(response);
	const results = response.results;
	const id_topic = response.id_topic;
	const id_subtopic = response.id_subtopic;
	const type = response.type;
    const compiledTemplate = Handlebars.getTemplate('autocomplete_cta');
	const html = compiledTemplate({ctas: results, id_topic: id_topic, type: type, id_subtopic: id_subtopic, topicName});
    document.querySelector(response.divClass).innerHTML = html;
    addEventAutosuggestionCta('.autosuggestion_cta');
}

const addEventAutosuggestionCta = (classe) => {
	const elements = document.querySelectorAll(classe);
	for (let i = elements.length - 1; i >= 0; i--) {
		elements[i].addEventListener('click', ctaSelected);
	}
}

// Return the icon path based on the CTA Type
const ctaIcon = (tipoCta) => {
	const imgPath = `${window.location.origin}/assets/images/`;
	let icon = '';
	switch (tipoCta) {
		case '0': // whitepaper
		case '1': // infographic
			icon = 'book.png';
			break;
		case '2': // event
			icon = 'event.png';
			break;
		case '3': // webinar
		case '4': // webcast
			icon = 'video.png';
			break;
		case '5': // newsletter
			icon = 'email.png';
			break;
		case '6': // other
		case '7': // autopromo
		case '8': // lgboost
		default:
			icon = 'star.png';
	}
	return imgPath + icon;
};

// Return the default weight based on the CTA Type
const ctaWeight = (tipoCta) => {
	let weight;
	switch (tipoCta) {
		case '0': // whitepaper
		case '1': // infographic
			weight = 2;
			break;
		case '2': // event
		case '3': // webinar
			weight = 3;
			break;
		case '4': // webcast
		case '5': // newsletter
		case '6': // other
		case '7': // autopromo
		case '8': // lgboost
		default:
			weight = 1;
	}
	return weight;
};

const ctaSelected = (event) => {
    const {
        id: idCta,
        titolo: titoloCta,
        tipo: tipoCta,
        id_topic,
        id_subtopic,
        type,
        sfdc_campaign_name,
        topicName,
		tipo: cta_type
    } = event.target.dataset;
	const containerAutosuggestion = event.target.parentNode.parentNode.parentNode;
	const input = containerAutosuggestion.parentNode.querySelector('.autocomplete_cta');
	const containerCtaList = containerAutosuggestion.parentNode;
	const listCta = containerCtaList.querySelector('.cta_list');
	const isDuplicate = listCta.querySelector('[data-id="'+idCta+'"]');
	if ( isDuplicate ) {
		M.toast({html: LANG.error_selected_cta});
		containerAutosuggestion.innerHTML = '';
		input.value = '';
		return;
	}
    const weight = LANG.weight;
	const compiledTemplate = Handlebars.getTemplate('cta_inside_topic');
	const html = compiledTemplate({ titolo: titoloCta, idCta: idCta, tipoCta: tipoCta, id_topic: id_topic, type: type, id_subtopic: id_subtopic, imgPath:ctaIcon(tipoCta), defaultWeight:ctaWeight(tipoCta), sfdcCampaignName: sfdc_campaign_name, topicName, weight: weight, cta_type });
	const ctaDiv = document.createElement('div');
	ctaDiv.innerHTML = html;
	listCta.appendChild(ctaDiv);
	containerAutosuggestion.innerHTML = '';
	input.value = '';
	addEventListenerToRemoveCtaBtn();
}

const showErrorMessage = (response) => {
	M.toast({html: response.msg});
}

const saveAll = (event) => {
	formUpdate = [];
	let obj = {};
	let errors = 0;
	const ctas = document.querySelectorAll('.cta_list');
	for (let i = ctas.length - 1; i >= 0; i--) {
		let cta = ctas[i].querySelectorAll('.input-group');
		for (let z = cta.length - 1; z >= 0; z--) {
			const id_cta = cta[z].querySelector('input[name="id_cta"]');
			const id_topic = cta[z].querySelector('input[name="id_topic"]').value != '' ? cta[z].querySelector('input[name="id_topic"]').value : '0';
			const id_subtopic = cta[z].querySelector('input[name="id_subtopic"]').value != ''  ? cta[z].querySelector('input[name="id_subtopic"]').value : '0';
			const peso = cta[z].querySelector('input[name="peso"]');
            const topicName = cta[z].querySelector('input[name="topic_name"]');
			const cta_type = cta[z].querySelector('input[name="cta_type"]');
			obj = {
                id_cta: id_cta.value,
                id_topic,
                id_subtopic,
                peso: peso.value,
                topic_name: encodeURIComponent(topicName.value),
				cta_type: cta_type?.value ? cta_type.value : '',
            };
			formUpdate.push(obj);
		}
	}
	const payload = 'data='+JSON.stringify(formUpdate)+'&ctatodelete='+JSON.stringify(ctaToRemove);
	const endpoint = window.location.origin + '/cta/addCtaToTopics/';
	sendXhrRequest(endpoint,payload,'POST').then(successRequestSave).catch(showErrorMessage);
}


const successRequestSave = (response) => {
	response = JSON.parse(response);
	M.toast({html: response.msg});
	setTimeout(function(){
		window.location.reload();
	},1500);
}

const addEventListenerToRemoveCtaBtn = () => {
	const removeCtaBtns = document.querySelectorAll('.removeCta');
    if ( removeCtaBtns ) {
    	for (let i = removeCtaBtns.length - 1; i >= 0; i--) {
			removeCtaBtns[i].addEventListener('click', removeCta);
		}
    }
}

const removeCta = (event) => {
	if( confirm(LANG.confirm_delete_cta)){
		const cta = event.target.parentNode.parentNode.parentNode;
		const id_cta = cta.querySelector('input[name="id_cta"]').value;
		const id_topic = cta.querySelector('input[name="id_topic"]').value;
		const id_subtopic = cta.querySelector('input[name="id_subtopic"]').value;		
		ctaToRemove.push({id_cta: id_cta, id_topic: id_topic, id_subtopic: id_subtopic});
		cta.remove();
	}
}

const calcolaPeso = (cta) => {
	const parent = cta;
	let pesoTot = 0;
	const pesiEl = cta.querySelectorAll('.peso-input');
	for (let i = pesiEl.length - 1; i >= 0; i--) {
		pesoTot += parseInt(pesiEl[i].value);
	}
    for (let i = pesiEl.length - 1; i >= 0; i--) {
    	const el = pesiEl[i];
    	const idCta = el.dataset.id_topic_cta;
    	let peso = parseInt(el.value);
    	if ( peso === 0 ) {
    		peso = 100;
    		pesoTot = 100;
    	} 
    	const percentuale = (100 / pesoTot) * peso; 
    	const percEl = cta.querySelector('[data-id_topic_cta_perc="' + idCta + '"]');
    	percEl.innerHTML = percentuale.toFixed(2) + ' %';
	}


	
}


export default initTopic;
