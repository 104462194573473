import Dashboard from './dashboard';
import Topic from './topic';
import Cta from './cta';
import MediaLibrary from './media-library';
import CtaPreview from './cta-preview';
import ControlloSalvataggio from './controllo-salavaggio';
import SortTable from './sort-table';

const lowerCasePath = window.location.pathname.toLowerCase();
const routes = {
	'/cta': './cta-list',
	'/dashboard': './dashboard'
};

document.addEventListener('DOMContentLoaded', function(){
	Dashboard();
	Topic();
	Cta();
	MediaLibrary();
	ControlloSalvataggio(); 

	if ( routes[lowerCasePath] ) {
		import( './cta-list' ).then( CtaListModule => {
			const CtaList = CtaListModule.default;
			CtaList(); 
		});
	}
});

window.addEventListener('load', function(){
	CtaPreview();
	SortTable();
});

