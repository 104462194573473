/* global LANG:readonly */

let formHbDiv;
let ctaFooter;
let ctaFooterTanks;
let timestampCounterStop;

const initCtaPreview = () => {
	const ctaBtn = document.querySelector('.cta-btn');
	const countdown = document.querySelector('.countdown');
	formHbDiv = document.querySelector('.hb-form-wrapper');
	ctaFooter = document.querySelector('.cta-footer');
	ctaFooterTanks = document.querySelector('.cta-footer-tanks');
	if ( ctaBtn ) {
		ctaBtn.addEventListener('click',showFormHb);
	}
	if ( countdown ) {
		setInterval(startTimer, 1000, countdown);
		timestampCounterStop = parseInt(countdown.dataset.timestamp) * 1000;
	}
}

const startTimer = (countdown) => {
	let now = new Date().getTime();
	const distance = timestampCounterStop - now;
	const days = Math.floor(distance / (1000 * 60 * 60 * 24));
	const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((distance % (1000 * 60)) / 1000);
	if ( distance > 0 ) {
		countdown.innerHTML = '<span class="countdown__text">' + LANG.start_in + ':</span> <div class="gio date-time-counter">'+days+'</div><div class="ore date-time-counter">'+hours+'</div><div class="min date-time-counter">'+minutes+'</div><div class="sec date-time-counter">'+seconds+'</div><div class="clearfix"></div>';
	} else {
		countdown.innerHTML = '';
	}
}

const showFormHb = () => {
	formHbDiv.classList.remove('hide');
	ctaFooter.classList.add('hide');
}


















export default initCtaPreview;
