import sendXhrRequest from './request';
import Handlebars from './handlebars';

/* global LANG:readonly */

let searchField;
let topicTableBody;
const urlBase = window.location.href;

const initDashboard = () => {
	$('.arrow-down').click(function(){
	    $('#search-bar').slideToggle();
	});
	const closeBtnSearchbar = document.querySelector('.close-searchbar');
	searchField = document.querySelector('#search');
	if ( closeBtnSearchbar ) {
		closeBtnSearchbar.addEventListener('click', resetSearchDashboard);
	}
	
	const aggiungiTopic = document.querySelector('#aggiungi_topic');
	if ( aggiungiTopic ) {
		aggiungiTopic.addEventListener('click',addTopic);
	}
	topicTableBody = document.querySelector('#topic tbody');
}

const resetSearchDashboard = (event) => {
	const controller = event.target.dataset.controller;
	location.href = window.location.origin+'/'+controller;
}

const addTopic = (event) =>  {
	const time = Date.now();
	const newTopic = LANG.new_topic;
	const compiledTemplate = Handlebars.getTemplate('aggiungi_topic');
	const html = compiledTemplate({ time, newTopic });
	topicTableBody.innerHTML = html + topicTableBody.innerHTML;
	M.updateTextFields();
}

export default initDashboard;
