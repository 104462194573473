const Handlebars = require('handlebars');

Handlebars.getTemplate = function(name) {
	if (Handlebars.templates === undefined || Handlebars.templates[name] === undefined) {
		$.ajax({
			url : '/assets/hbtemplates/' + name + '.hbs',
			success : function(data) {
				if (Handlebars.templates === undefined) {
					Handlebars.templates = {};
				}
				Handlebars.templates[name] = Handlebars.compile(data);
			},
			async : false
		});
	}
	return Handlebars.templates[name];
};

Handlebars.registerHelper('ifeq', function (a, b, options) {
    if (a == b) { 
    	return options.fn(this);
    }
    return options.inverse(this);
});

Handlebars.registerHelper('ifnoteq', function (a, b, options) {
    if (a != b) { 
    	return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('data-sort', function(type) {
    switch (type) {
        case '0':
            return 'WHITEPAPER';
        case '1':
            return 'INFOGRAFICA';
        case '2':
            return 'EVENTO';
        case '3':
            return 'WEBINAR';
        case '4':
            return 'WEBCAST';
        case '5':
            return 'NEWSLETTER';
        case '6':
            return 'ALTRO';
        case '7':
            return 'AUTOPROMO';
        case '8':
            return 'LG_BOOST';
        
    }
});

Handlebars.registerHelper('capitalize', function(value) {
    if (typeof value !== 'string') {
        return value;
    }
    
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Handlebars.registerHelper('imageUrl', function(type, base_url) {
    switch (type) {
        case '0':
        case '1':
            return `${base_url}/assets/images/book.svg`;
        case '2':
            return `${base_url}/assets/images/event.svg`;
        case '3':
        case '4':
            return `${base_url}/assets/images/video.svg`;
        case '5':
            return `${base_url}/assets/images/email.svg`;
        case '6':
        case '7':
        case '8':
            return `${base_url}/assets/images/star.svg`;
    }
});

export default Handlebars;
