import Dashboard from './dashboard';
import Topic from './topic';
import Cta from './cta';
import MediaLibrary from './media-library';
import CtaPreview from './cta-preview';
import ControlloSalvataggio from './controllo-salvataggio';
import SortTable from './sort-table';

document.addEventListener('DOMContentLoaded', function(){
	Dashboard();
	Topic();
	Cta();
	MediaLibrary();
	ControlloSalvataggio(); 
});

window.addEventListener('load', function(){
	CtaPreview();
	SortTable();
});

